import Vue from 'vue'
import axios from "axios";
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import Toaster from 'v-toaster';

import 'v-toaster/dist/v-toaster.css';

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

Vue.use(Toaster, {timeout: 5000});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
