<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="h-100">
        <v-row align="center" justify="center" class="h-100">
          <v-col cols="12" sm="4">
            <v-card class="elevation-3">
              <form>
                <v-card-title>
                  Авторизация
                </v-card-title>
                <v-card-text>
                  <v-text-field
                      v-model="phone"
                      label="Номер телефона"
                      required
                  ></v-text-field>
                  <v-text-field
                      v-model="password"
                      label="Пароль"
                      required
                      type="password"
                  ></v-text-field>
                  <v-btn
                      class="mr-4"
                      @click="login"
                  >
                    Войти
                  </v-btn>
                </v-card-text>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "GuestLayout",
  data() {
    return {
      phone: null,
      password: null,
    }
  },
  methods: {
    login() {
      const app = this;

      axios.post('/api/login', {
        phone: app.phone,
        password: app.password,
      }).then(response => {
        const result = response.data.data;

        if (result.code === 'error') {
          alert(result.result.message);
        } else {
          localStorage.setItem('token', result.result.token);

          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
        }
      });
    }
  }
}
</script>

<style scoped>

</style>