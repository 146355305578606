export default {
    setCategoryList(state, payload) {
        state.category_list = payload;
    },
    setTotalCount(state, payload) {
        state.total_count = payload;
    },
    setPolcarSubModelModel(state, payload) {
        state.category_list[payload.index].model_list[payload.model_index].sub_model_list[payload.sub_model_index].model = payload.value;
    },
    changeSubCategoryGeneration(state, payload) {
        state.category_list[payload.index].model_list[payload.model_index].sub_model_list[payload.sub_model_index].generation = payload.value;
    },
}
