export default {
    getCategoryList: (state) => {
        return state.category_list;
    },
    getTotalCount: (state) => {
        return state.total_count / 10;
    },
    getProviderPolcarSubModel: (state) => (index, model_index, sub_model_index) => {
        return state.category_list[index].model_list[model_index].sub_model_list[sub_model_index].model;
    },
    getProviderPolcarSubModelGeneration: (state) => (index, model_index, sub_model_index) => {
        return state.category_list[index].model_list[model_index].sub_model_list[sub_model_index].generation;
    },
}
