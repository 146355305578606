export default {
    name: "AppLayout",
    components: {

    },
    data: () => ({
        cards: ['Today', 'Yesterday'],
        drawer: null,
        links: [
            ['mdi-inbox-arrow-down', 'Категории Полкар', '/catalog/categories/polcar'],
            ['mdi-inbox-arrow-down', 'Поставщики', '/providers'],
        ],
    }),
}
