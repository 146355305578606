import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

function loggedIn()
{
  return localStorage.getItem('token');
}

const router = new VueRouter({
  mode: 'history',

  routes: [
    {
      path: '/login', component: () => import('../components/Login/Login.vue'),
      name: 'login',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/', component: () => import('../components/Home/Home.vue'),
      name: 'home',
      meta: {requiresAuth: true, layout: 'AppLayout'},
    },
    {
      path: '/catalog/categories/:provider/:page?', component: () => import('../components/Catalog/Categories/Categories.vue'),
      name: 'catalog-categories',
      meta: {requiresAuth: true, layout: 'AppLayout'},
    },
    {
      path: '/providers', component: () => import('../components/Providers/Providers.vue'),
      name: 'providers',
      meta: {requiresAuth: true, layout: 'AppLayout'},
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (loggedIn()) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  }
});

export default router;
