import axios from "axios";

export default {
    loadCategoryList({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/categories/list', payload).then((response) => {
                const result = response.data.data;

                commit('setCategoryList', response.data.data.result.category_list);
                commit('setTotalCount', response.data.data.result.total_count_category);
            }).catch((error) => {
                //reject(false);
            });
        });
    },
    savePolcarSubModel({ commit, state }, payload)
    {
        const app = this;

        return new Promise((resolve, reject) => {
            axios.post('/api/categories/save-polcar-sub-model', payload).then((response) => {
                const result = response.data.data;

                if (result.code == 'ok' && result.result.success == true) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error) => {
                //reject(false);
            });
        });
    },
    setPolcarSubModelModel({ commit, state }, payload)
    {
        if (state.category_list.length) {
            if (state.category_list[payload.index]) {
                if (
                    state.category_list[payload.index].model_list
                    && state.category_list[payload.index].model_list.length
                    && state.category_list[payload.index].model_list[payload.model_index]
                ) {
                    if (
                        state.category_list[payload.index].model_list[payload.model_index].sub_model_list
                        && state.category_list[payload.index].model_list[payload.model_index].sub_model_list.length
                        && state.category_list[payload.index].model_list[payload.model_index].sub_model_list[payload.sub_model_index]
                    ) {
                        let sub_model = state.category_list[payload.index].model_list[payload.model_index].sub_model_list[payload.sub_model_index];

                        commit('setPolcarSubModelModel', payload);
                    }
                }
            }
        }
    },
    changeSubCategoryGeneration({ commit, state }, payload)
    {
        if (state.category_list.length) {
            if (state.category_list[payload.index]) {
                if (
                    state.category_list[payload.index].model_list
                    && state.category_list[payload.index].model_list.length
                    && state.category_list[payload.index].model_list[payload.model_index]
                ) {
                    if (
                        state.category_list[payload.index].model_list[payload.model_index].sub_model_list
                        && state.category_list[payload.index].model_list[payload.model_index].sub_model_list.length
                        && state.category_list[payload.index].model_list[payload.model_index].sub_model_list[payload.sub_model_index]
                    ) {
                        let sub_model = state.category_list[payload.index].model_list[payload.model_index].sub_model_list[payload.sub_model_index];

                        commit('changeSubCategoryGeneration', payload);
                    }
                }
            }
        }
    }
}
